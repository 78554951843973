import * as React from 'react'
import BlogPostOld from "../../components/blog-post-old";
import image from "../../images/blog-7.jpg"

export default function DitchTheSitupsYourBackWillThankYou(){
    return(
        <BlogPostOld
            src={image}
            title={"Ditch the Situps: Your Back will Thank You"}
            summary={"Whether you want to learn how to stop your low back pain or just want to know how to avoid back pain altogether, this one is for you. Read on to learn how to stop pissing off your low back AND build a ripped core."}
            date={"September 4, 2019"}
            author={"Dr. Donald Mull, DC"}
        >
            <div className="rich-text limited w-richtext"><p><br /></p><p>By now most of us know that effective core training is essential for staying active and pain free. In an effort to build strength (and six pack abs) many people rely on moves like sit-ups, crunches and twists that involve a lot of spinal movement. Unfortunately too much spinal movement – particularly moves with rotation such as Russian twists, sit up to twists or wood choppers – is both unnecessary and potentially harmful over the long run. </p><p>As part of our holistic approach to healthy training and lifestyle, we recommend regular core training focused on stability. This kind of training is safer, just as effective and creates a strong foundation for movement-based hobbies or athletic competition. So you can work smarter in the gym and still have the energy and freedom of movement to play a weekend round of golf, compete in a 5K or replant your garden.</p><p>Specifically we recommend any exercise focused on stillness or “anti-movement” that teaches the torso to stay calm and resist force. Although sometimes these exercises appear to be easier than sit-ups or crunches when done with proper form you will really feel the burn.</p><p>Here are our favorite anti-movement exercises for building a healthy core. We suggest performing each move 2-3 times a day at least four or five days per week </p><p> </p><p>1. <a href="https://vimeo.com/314942035/69089b9fd0" target="_blank">Plank</a> – hold on elbows 30 to 45 seconds per rep</p>
                <iframe className={"w-full"} title="vimeo-player" src="https://player.vimeo.com/video/314942035?h=69089b9fd0" width="640"
                        height="640" frameBorder="0" allowFullScreen></iframe>
                <p>2. <a href="https://vimeo.com/316469200/468c1fca79" target="_blank">Dead bug with leg extensions</a> – 5 to 8 reps per side</p>
                <iframe className={"w-full"} title="vimeo-player" src="https://player.vimeo.com/video/316469200?h=468c1fca79" width="640"
                        height="640" frameBorder="0" allowFullScreen></iframe>
                <p>3. <a href="https://vimeo.com/315028739/07b39cbf90" target="_blank">Side plank</a> – 30 seconds per side</p>
                <iframe className={"w-full"} title="vimeo-player" src="https://player.vimeo.com/video/315028739?h=07b39cbf90" width="640"
                        height="640" frameBorder="0" allowFullScreen></iframe>
                <p>4. <a href="https://vimeo.com/314942548/9382b038f8" target="_blank">Bird Dog</a> – 6 to 8 reps per side</p>
                <iframe className={"w-full"} title="vimeo-player" src="https://player.vimeo.com/video/314942548?h=9382b038f8" width="640"
                        height="640" frameBorder="0" allowFullScreen></iframe>
                <p><strong>Want More?</strong></p><p>Click <a href="https://kirehab.webflow.io/5tips-low-back-pain" target="_blank">here</a> or the picture below to download your FREE 5 Tips to Fight Low Back Pain!</p><figure className="w-richtext-align-center w-richtext-figure-type-image"><a href="https://kirehab.webflow.io/5tips-low-back-pain" target="_blank" className="w-inline-block"><div><img src="https://uploads-ssl.webflow.com/5d48890c4337d1e41253e73e/5d719220247fde18873ba865_5%20Tips%20to%20Fight%20LBP.png" alt="" /></div></a></figure><ul role="list"><li>This guide will teach you simple guidelines on staying active while keeping your back as safe as possible!</li></ul><p>‍</p></div>
        </BlogPostOld>

    )
}